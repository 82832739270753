body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  color: #444;
}

#root {
  height: 100vh;
}

code {
  background: #efefef;
  color: #000;
  padding: .25rem;
  border-radius: .25rem;
  display: inline-block;
  margin: 0;
  vertical-align: text-top;
}

h1, h2 {
  font-weight: normal;
}

h3 {
  font-size: 1em;
}