.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App header {
  border-bottom: solid lightgray 1px;
  padding: 1rem;
}

.App header h1 {
  margin: 0;
}

.App .main {
  flex: 1;
  display: flex;
}

.App .main nav {
  border-right: solid lightgray 1px;
  padding: 1rem;
}

.App .main nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.App .main nav a {
  display: block;
  padding: .5rem 0;
  color: black;
  text-decoration: none;
}

.App .main nav a.active {
  color: lightskyblue;
  text-decoration: none;
}

main {
  flex: 1;
}
